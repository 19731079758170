import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { CategoriessectiontwoComponent } from './homeone/categoriessectiontwo/categoriessectiontwo.component';
import { FooteroneComponent } from './common/footer/footerone/footerone.component';
import { HeaderoneComponent } from './common/header/headerone/headerone.component';
import { MobilemenuComponent } from './common/header/mobilemenu/mobilemenu.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxLiteYoutubeModule } from 'ngx-lite-video'
import { MatFormFieldModule } from '@angular/material/form-field';
import { JejakimaniSafeHtmlPipe } from '../helpers/jejakimani-safe-html';
import { JejakImaniTitlePipe } from '../helpers/jejakimani-title.pipe';
import { JejakImaniStripHtmlPipe } from '../helpers/jejakimani-strip-html.pipe';
import { PhoneNumberPipe } from '../helpers/phone-number.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { CounterInputComponent } from './common/counter-input/counter-input.component';
import { UcwordsPipe } from '../helpers/ucwords';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    HttpClientModule,
    SwiperModule,
    EditorModule,
    NgOptimizedImage,
    NgxLiteYoutubeModule,
    NgxPaginationModule
  ],
  declarations: [
    CategoriessectiontwoComponent,
    FooteroneComponent,
    HeaderoneComponent,
    MobilemenuComponent,
    JejakImaniTitlePipe,
    JejakImaniStripHtmlPipe,
    JejakimaniSafeHtmlPipe,
    PhoneNumberPipe,
    CounterInputComponent,
    UcwordsPipe
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    HttpClientModule,
    SwiperModule,
    EditorModule,
    NgOptimizedImage,
    NgxLiteYoutubeModule,
    NgxPaginationModule,
    CategoriessectiontwoComponent,
    FooteroneComponent,
    HeaderoneComponent,
    MobilemenuComponent,
    JejakImaniTitlePipe,
    JejakImaniStripHtmlPipe,
    JejakimaniSafeHtmlPipe,
    PhoneNumberPipe,
    CounterInputComponent,
    UcwordsPipe
  ]
})
export class JejakImaniModule { }
